<template>
  <div id="ship-track-no-results">
    <div class="no-results-header">EFW Tracking</div>
    <div class="no-results-content">
      Tracking Number <b>{{ this.trackingNumber }}</b> was not found. Please
      verify the number or contact our Control Tower: 1(855) 633-9669
    </div>
  </div>
</template>

<script>
export default {
  name: "ship-track-no-results",
  data() {
    return {};
  },
  methods: {},
  created: function () {
    // console.log('ship-track-no-results: created')
  },
  props: {
    trackingNumber: {
      default: "undefined",
      type: String,
    },
  },
  components: {},
};
</script>

<!-- styling for the component -->
<style scoped>
#ship-track-no-results {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16),
    0 -2px 10px 0 rgba(0, 0, 0, 0.12);
}

.no-results-header {
  padding: 20px;
  color: #ffffff;
  background-color: #002d5b;
  visibility: visible;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.no-results-content {
  color: black;
  padding: 60px;
}
</style>
