// Request URL: https://efwtrack.com/api/ShipmentTracking/get_target_tracking?TrackNo=afsafasf
import axios from "axios";

var webservices = {
  // api: 'https://efwtrack.com/api/',
  api: "/api",
  json: "/json",
  // worldTrakApi: 'https://dev.efwnow.com',
  worldTrakApi: "",
  requestHeaders: function () {
    let headers = {};
    return headers;
  },
  queryString: function (data) {
    console.log(data);
    var params = [];
    for (var key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        params.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        );
      }
    }

    if (params.length > 0) {
      return params.join("&");
    }

    return "";
  },
  getTracking: function (data) {
    console.log("Get tracking here");
    return axios({
      method: "POST",
      url: this.api + "/ShipmentTracking/get_shipment_tracking",
      data: this.queryString(data),
      headers: this.requestHeaders(),
    });
  },
  getAlerts: function () {
    return axios({
      method: "GET",
      url: this.json + `/alert.json`,
      headers: this.requestHeaders(),
    });
  },
  authorizeTextUpdates: function (trackingNum, phoneNum) {
    let bodyXml =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"' +
      ' xmlns:tem="http://tempuri.org/">' +
      " <soapenv:Header/><soapenv:Body>" +
      "<tem:UpdateConsigneePhone>" +
      "<tem:TrackingNo>" +
      trackingNum +
      "</tem:TrackingNo>" +
      "<tem:ConsigneePhone>" +
      phoneNum +
      "</tem:ConsigneePhone>" +
      "<tem:OptIn>Y</tem:OptIn>" +
      "<tem:ScheduledUser>EFWTOptIn</tem:ScheduledUser>" +
      "</tem:UpdateConsigneePhone>" +
      "</soapenv:Body>" +
      "</soapenv:Envelope>";
    return (
      axios
        // .post(this.worldTrakApi + '/WorldTrakInteractiveDeliveryService/ApptDel.asmx?op=UpdateConsigneePhone',
        .post(
          "/WorldTrakInteractiveDeliveryService/ApptDel.asmx?op=UpdateConsigneePhone",

          bodyXml,
          { headers: { "Content-Type": "text/xml" } }
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        })
    );
  },
};

export default webservices;
