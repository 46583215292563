<template>
  <div v-if="isVisible" class="chat-help-container">
    <div class="chat-help-close-button" @click="isVisible = false">X</div>
    <div class="chat-help-row first-row">Do you need help with your order?</div>
    <div class="chat-help-row second-row">Chat with us here!</div>
  </div>
</template>

<script>
export default {
  name: "ship-track-chat-help-dialog",
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    this.isVisible = true;
    // setTimeout(() => {
    //   this.isVisible = true
    // }, 3000)
  },
};
</script>

<style scoped>
.chat-help-container {
  background-color: #fff;
  bottom: 24px;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  position: fixed;
  right: 190px;
  width: 175px;
  z-index: 200;
}
.chat-help-container:after {
  background-color: #fff;
  -webkit-box-shadow: 3px 3px 5px 0 #ccc;
  -moz-box-shadow: 3px 3px 5px 0 #ccc;
  box-shadow: 3px 3px 5px 0 #ccc;
  content: "\00a0";
  height: 14px;
  right: -7px;
  position: absolute;
  top: 65%;
  -webkit-transform: rotate(0.875turn);
  -moz-transform: rotate(0.875turn);
  transform: rotate(0.875turn);
  width: 14px;
}
.chat-help-close-button {
  align-items: center;
  background-color: #d3d3d3;
  border: 1px solid #333;
  border-radius: 50%;
  color: #333;
  display: flex;
  font-weight: bold;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: -13px;
  top: -13px;
  width: 25px;
}
.chat-help-close-button:hover {
  cursor: pointer;
}
.chat-help-row {
  text-align: left;
}
.first-row {
  color: #000;
  font-weight: bold;
}
.second-row {
  color: #fc4d02;
  font-weight: bold;
}
</style>
