<template>
  <div v-if="enabled" :class="alertMessageClass">
    <div v-if="showDate">
      <span class="alert-date">{{ date }}</span>
    </div>
    <span class="alert-text">
      {{ message }}
    </span>
  </div>
</template>

<script>
import webservices from "@/webservices";

export default {
  name: "alert-message",
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alertMessageClass() {
      return {
        alertmessage: true,
        alertmessage_standard: !this.inverted,
        alertmessage_inverted: !!this.inverted,
      };
    },
    showDate() {
      return !this.inverted && this.date && this.date !== "";
    },
  },
  data() {
    return {
      enabled: false,
      date: "",
      message: "",
    };
  },
  methods: {
    async loadAlert() {
      console.log("Alert Message Loading...");
      try {
        let { data } = await webservices.getAlerts();
        if (data && data.enabled && data.message) {
          this.enabled = data.enabled;
          this.date = data.date;
          this.message = data.message;
        }
      } catch (e) {
        this.enabled = false;
      }
    },
  },
  mounted() {
    this.loadAlert();
  },
};
</script>

<style scoped>
.alertmessage {
  text-align: left;
  font-weight: bold;
  font-size: 0.92em;
  padding: 15px 25px 15px 25px;
}

.alertmessage_standard {
  color: white;
  background-color: #fc4c02;
}

.alertmessage_inverted {
  color: #fc4c02;
  background-color: white;
}

.alert-text {
}
.alert-date {
}
</style>
