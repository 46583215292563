<template>
  <transition name="video-modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-checked="undefined"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ serviceLevelDescription }}
          </slot>
          <div @click="close" class="modal-button__close">
            <a>&#x2715;</a>
          </div>
          <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
          </div>
        </header>
        <div class="video-div">
          <!--          <img class="fallback" v-if="!playerReady" src="https://i.vimeocdn.com/portrait/32983602_600x337.jpg" alt="loading image"/>-->
          <iframe
            ref="playerElement"
            :src="video.src"
            allowFullScreen
            @load="onReady"
            id="playerId"
          >
          </iframe>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Player from "@vimeo/player";
import { toRaw } from "vue";

export default {
  name: "VideoModal",
  computed: {
    serviceLevelDescription() {
      return this.serviceLevel;
    },
  },
  watch: {
    isVideoModalVisible() {
      if (this.isVideoModalVisible === true) {
        document.addEventListener("click", this.handleClick);
        document.addEventListener("touchstart", this.handleClick);
        document.addEventListener("keydown", this.handleKeyDown);
      } else {
        document.removeEventListener("click", this.handleClick);
        document.removeEventListener("touchstart", this.handleClick);
        document.removeEventListener("keydown", this.handleKeyDown);
      }
    },
  },
  data() {
    return {
      player: null,
      isLoading: false,
      playerReady: false,
      playerOptions: {
        controls: true,
        autoplay: false,
        transparent: true,
        language: "en",
        portrait: false,
        height: 360,
        width: 640,
      },
    };
  },
  methods: {
    onReady() {
      this.playerReady = true;
      this.player = new Player("playerId", this.playerOptions);
    },
    stop() {
      // toRaw is need to remove reactivity proxy wrapper that Vue 3 ads.
      const playerUnwrapped = toRaw(this.player);
      playerUnwrapped.pause(); // cease playback
      playerUnwrapped.setCurrentTime(0); // reset to beginning
    },
    close() {
      this.stop();
      this.$emit("close"); // tell parent video is closed
    },
    handleClick(e) {
      const backdrop = document.querySelector(".modal-backdrop");
      const modal = document.querySelector(".modal");
      if (e.target === backdrop && e.target !== modal) this.close();
    },
    handleKeyDown(e) {
      if (e.key === "Escape" || e.code === "Escape" || e.keyCode === 27) {
        this.close();
      }
    },
  },
  unmounted() {
    document.removeEventListener("click", this.handleClick);
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  props: {
    isVideoModalVisible: { type: Boolean, required: true },
    serviceCode: { type: String, required: true },
    serviceLevel: { type: String, required: true },
    video: { type: Object, required: true },
  },
};
</script>

<style scoped>
a {
  color: #002d5b;
}
label {
  text-align: left;
  width: 93%;
  display: block;
}

/* VIDEO/iFRAME STYLES */
.video-div {
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  height: 0;
}
img.fallback,
.video-div > iframe {
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.video-div > iframe.loading {
  height: 0;
  width: 0;
}

/* BUTTON STYLES */
.modal-button__close {
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-right: -0.3515626rem !important; /* ( ((total width) - (width of inner <a>)) / 16 ) / 2 */
  width: 25px;
}
.modal-button__close > a {
  text-decoration: none;
  width: 0.858125rem;
}
.modal-button__close:focus,
.modal-button__close:hover > a {
  color: rgb(0, 173, 239);
  outline: 0;
}

/* MODAL STYLES */
.modal {
  background: #fff;
  border: 2px solid #002d5b;
  border-radius: 6px;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px 20px 20px 20px;
  width: 50%;
}
.modal-backdrop {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal,
.modal-backdrop,
.modal-header {
  display: flex;
}
.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #002d5b;
  flex-direction: row;
  font-size: large;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px 0;
}

/* LOADER */
.loader-container {
  display: flex;
  text-align: center;
}
.loader {
  animation: spin 1s linear infinite;
  background: url("../../assets/loading.svg") center no-repeat;
  border-radius: 50%;
  height: 25px;
  margin: 0 auto !important;
  padding: 0;
  width: 25px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*-------------------
    Media Queries
  -------------------*/
@media screen and (max-width: 767px) {
  .modal {
    width: 75%;
  }
}
@media screen and (max-width: 576px) {
  .modal {
    width: 80%;
  }
}
@media screen and (max-width: 460px) {
  .modal {
    width: 85%;
  }
}
@media screen and (max-width: 375px) {
  /*.modal-header { flex-direction: column-reverse; }*/
  .modal-header {
    font-size: 16px;
  }
}
</style>
