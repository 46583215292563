import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_4,
    params: {
      send_page_view: false,
    },
  },
});
app.mount("#app");
