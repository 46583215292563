<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-checked="undefined"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> Text Updates </slot>
          <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
          </div>
        </header>
        <section style="padding-top: 10px">
          <div v-if="isRegisteredSuccess" class="registration-status-success">
            <label style="text-align: left"
              >You're now registered for text updates!</label
            >
          </div>
          <div
            v-else-if="isRegisteredFailure"
            class="registration-status-failure"
          >
            <label style="text-align: left"
              >Sorry, error occurred! Please try again.</label
            >
          </div>
        </section>
        <section class="modal-phone" id="modalDescription">
          <input
            type="text"
            v-model="phoneNumber"
            v-maska:[options]
            placeholder="phone #"
          />

          <!--          <the-mask-->
          <!--            mask="(###) ###-####"-->
          <!--            type="text"-->
          <!--            v-model="phoneNumber"-->
          <!--            placeholder="phone #"-->
          <!--          >-->
          <!--          </the-mask>-->
        </section>
        <section class="modal-terms" id="undefined">
          <input
            v-model="checked"
            class="checkbox"
            type="checkbox"
            id="checkbox"
          />
          <label
            >By clicking this box you are accepting the
            <a href="#" @click="showModalTerms()">Terms and Conditions</a>
          </label>
          <modal-terms v-show="isModalTermsVisible" @close="closeModalTerms">
          </modal-terms>
        </section>
        <footer class="modalFooter">
          <slot name="footer">
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              Close
            </button>
            <button
              :disabled="buttonDisabled"
              @click="submit"
              :class="{
                btnSubmit: !buttonDisabled,
                btnDisabled: buttonDisabled,
              }"
            >
              Submit
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import webservices from "@/webservices";
import modalTerms from "@/components/shiptrack/ShipTrackModalTerms.vue";
import { vMaska } from "maska";

export default {
  name: "TextUpdatesModal",
  directives: { maska: vMaska },
  components: {
    modalTerms,
  },
  data: function () {
    return {
      options: {
        mask: "(###) ###-####",
        eager: true,
      },
      isModalTermsVisible: false,
      isRegisteredSuccess: false,
      isRegisteredFailure: false,
      isLoading: false,
      buttonDisabled: true,
      phoneNumber: "",
      checked: false,
    };
  },
  watch: {
    phoneNumber() {
      this.buttonDisabled = !this.isInputValid();
    },
    checked() {
      this.buttonDisabled = !this.isInputValid();
    },
  },
  methods: {
    showModalTerms() {
      this.isModalTermsVisible = true;
      this.buttonDisabled = false;
    },
    closeModalTerms() {
      let cleanPhoneNumber = this.phoneNumber.replace(/\D/g, "");
      if (!(this.checked && cleanPhoneNumber.length === 10)) {
        this.buttonDisabled = true;
      }
      this.isModalTermsVisible = false;
    },
    isIE: function isIE() {
      var ua = navigator.userAgent;
      // MSIE used to detect old browsers and Trident used to newer ones*/
      return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    },
    isInputValid: function () {
      let cleanPhoneNumber = this.phoneNumber.replace(/\D/g, "");
      return (
        cleanPhoneNumber.length === 10 &&
        this.checked === true &&
        this.trackingNum.schedulingtrackingnumber
      );
    },
    submit: function () {
      this.isLoading = true;
      this.buttonDisabled = true;
      this.authorizeTextUpdates();
    },
    clearInput: function () {
      this.isLoading = false;
      this.isRegisteredSuccess = false;
      this.isRegisteredFailure = false;
      this.phoneNumber = "";
      this.checked = false;
      this.buttonDisabled = true;
    },
    close() {
      this.$emit("close");
      this.clearInput();
    },
    authorizeTextUpdates: function () {
      // update user phone number on the server2
      webservices
        .authorizeTextUpdates(
          this.trackingNum.schedulingtrackingnumber,
          this.phoneNumber.replace(/\D/g, "")
        )
        .then((response) => {
          this.isLoading = false;
          this.buttonDisabled = false;
          let responseValue;
          let parser = new DOMParser();
          // parse Xml to get result, currently ok response is on everything, looking for 6
          if (this.isIE()) {
            if (window.DOMParser) {
              responseValue = parser
                .parseFromString(response.data, "text/xml")
                .getElementsByTagName("UpdateConsigneePhoneResult")
                .item(0).textContent;
              if (responseValue === "6") {
                this.isRegisteredSuccess = true;
              } else {
                this.isRegisteredFailure = true;
              }
            }
          } else {
            let xmlDoc = parser.parseFromString(response.data, "text/xml");
            responseValue = xmlDoc
              .getElementsByTagName("UpdateConsigneePhoneResult")
              .item(0).innerHTML;
            if (responseValue === "6") {
              this.isRegisteredSuccess = true;
            } else {
              this.isRegisteredFailure = true;
            }
          }
        })
        .catch((error) => {
          this.isRegisteredFailure = true;
          this.buttonDisabled = false;
          this.isLoading = false;
          console.log("Error on response: ", error);
        });
    },
  },
  props: {
    trackingNum: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.btn-close:focus {
  outline: 0;
}
.btnSubmit:focus {
  outline: 0;
}
.modal-phone:focus {
  outline: 0;
}
label {
  text-align: left;
  float: right;
  width: 93%;
  /*padding-left: 0px;*/
  display: block;
}

.modal-phone input {
  border: 1px solid #002d5b;
  border-radius: 6px;
  width: 280px;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 20px;
  min-width: 25%;
  outline: none;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 300px;
  background: #ffffff;
  border: 2px solid #002d5b;
  border-radius: 6px;
  /*box-shadow: 1px 1px 1px 1px;*/
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.modal-header,
.modal-footer {
  padding: 10px 0px;
  display: flex;
}

.modal-header {
  font-weight: bold;
  font-size: large;
  border-bottom: 1px solid #eeeeee;
  color: #002d5b;
  justify-content: space-between;
}

.modal-phone {
  display: flex;
  position: relative;
  padding: 10px 0 20px 0;
}
.modal-footer {
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: center;
}

.modalFooter {
  /*height: 100px;*/
  padding: 20px 0 10px 0;
  /*display: flex;*/
  /*justify-content: space-between;*/
}
.modalFooterLoading {
  height: 100px;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
}
.btn-close {
  font-size: medium;
  width: 100px;
  text-align: center;
  padding: 10px 20px;
  border: 2px solid #002d5b;
  border-radius: 6px;
  cursor: pointer;
  color: #002d5b;
  float: left;
  background: transparent;
}
.btnDisabled {
  font-size: medium;
  opacity: 0.3;
  padding: 10px 20px;
  float: right;
  background-color: #002d5b;
  border: 2px solid #002d5b;
  border-radius: 6px;
  width: 100px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: default;
  vertical-align: middle;
}
.btnSubmit {
  font-size: medium;
  width: 100px;
  padding: 10px 20px;
  color: #fff;
  background-color: #002d5b;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  float: right;
  vertical-align: middle;
  horiz-align: right;
  border: 2px solid #002d5b;
  border-radius: 6px;
  justify-content: flex-end;
}
.registration-status-success {
  color: green;
  font-weight: bold;
  display: flex;
  text-align: center;
}
.registration-status-failure {
  color: red;
  font-weight: bold;
  display: flex;
  text-align: center;
}

.loader-container {
  display: flex;
  text-align: center;
}
a {
  color: #002d5b;
}

.loader {
  background: url("../../assets/loading.svg") center no-repeat;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: 0 auto !important;
  padding: 0px 0px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
