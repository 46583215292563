<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-checked="undefined"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> Login </slot>
          <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
          </div>
        </header>
        <section style="padding-top: 10px">
          <div v-if="isPasswordSuccess" class="registration-status-success">
            <label style="text-align: left">Logging in!</label>
          </div>
          <div
            v-else-if="isPasswordFailure"
            class="registration-status-failure"
          >
            <label style="text-align: left"
              >Sorry, incorrect password! Please try again.</label
            >
          </div>
        </section>
        <section class="modal-terms">
          <label>Please authenticate to continue.</label>
          <label>Cancel will return you to the public site.</label>
        </section>
        <section class="modal-password" id="modalDescription">
          <input
            type="password"
            v-model="password"
            placeholder="password"
            @keydown.enter="validateLogin"
          />
        </section>
        <footer class="modalFooter">
          <slot name="footer">
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              Cancel
            </button>
            <button
              :disabled="buttonDisabled"
              @click="login"
              :class="{
                btnSubmit: !buttonDisabled,
                btnDisabled: buttonDisabled,
              }"
            >
              Submit
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "WalmartLoginModal",
  components: {},
  data: function () {
    return {
      isPasswordSuccess: false,
      isPasswordFailure: false,
      isLoading: false,
      buttonDisabled: true,
      password: "",
    };
  },
  watch: {
    password() {
      this.buttonDisabled = !this.isInputValid();
    },
  },
  methods: {
    isIE: function isIE() {
      var ua = navigator.userAgent;
      // MSIE used to detect old browsers and Trident used to newer ones*/
      return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    },
    isInputValid: function () {
      return this.password.length > 0;
    },
    clearInput: function () {
      this.isLoading = false;
      this.isPasswordSuccess = false;
      this.isPasswordFailure = false;
      this.password = "";
      this.buttonDisabled = true;
    },
    close: function () {
      this.$emit("close");
      this.clearInput();
    },
    login: function () {
      this.isLoading = true;
      this.buttonDisabled = true;
      let pass = this.password;
      if (pass === process.env.VUE_APP_WALMART_PASSWORD) {
        this.isPasswordSuccess = true;
        this.$store.dispatch("login", pass).catch((err) => console.log(err));
        setTimeout(this.close, 2000); // wait a couple seconds before closing modal
      } else {
        this.isLoading = false;
        this.isPasswordFailure = true;
      }
    },
    validateLogin: function () {
      if (this.password.length > 0) {
        // isInputValid always passed through (?)
        // console.log(this.isInputValid())
        // console.log(this.password.length)
        this.login();
      }
    },
    // props: {
    //   trackingNum: {
    //     type: Object,
    //     required: true
    //   }
  },
};
</script>

<style scoped>
.btn-close:focus {
  outline: 0;
}
.btnSubmit:focus {
  outline: 0;
}
.modal-password:focus {
  outline: 0;
}
label {
  text-align: left;
  float: right;
  width: 93%;
  /*padding-left: 0px;*/
  display: block;
}

.modal-password input {
  border: 1px solid #002d5b;
  border-radius: 6px;
  width: 280px;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 20px;
  min-width: 25%;
  outline: none;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 300px;
  background: #ffffff;
  border: 2px solid #002d5b;
  border-radius: 6px;
  /*box-shadow: 1px 1px 1px 1px;*/
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.modal-header,
.modal-footer {
  padding: 10px 0px;
  display: flex;
}

.modal-header {
  font-weight: bold;
  font-size: large;
  border-bottom: 1px solid #eeeeee;
  color: #002d5b;
  justify-content: space-between;
}

.modal-password {
  display: flex;
  position: relative;
  padding: 10px 0 20px 0;
}
.modal-terms {
  font-size: 14px;
  padding: 10px 0;
}
.modal-terms label {
  width: 100%;
}
.modal-footer {
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: center;
}

.modalFooter {
  /*height: 100px;*/
  padding: 20px 0 10px 0;
  /*display: flex;*/
  /*justify-content: space-between;*/
}
.modalFooterLoading {
  height: 100px;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
}
.btn-close {
  font-size: medium;
  width: 100px;
  text-align: center;
  padding: 10px 20px;
  border: 2px solid #002d5b;
  border-radius: 6px;
  cursor: pointer;
  color: #002d5b;
  float: left;
  background: transparent;
}
.btnDisabled {
  font-size: medium;
  opacity: 0.3;
  padding: 10px 20px;
  float: right;
  background-color: #002d5b;
  border: 2px solid #002d5b;
  border-radius: 6px;
  width: 100px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: default;
  vertical-align: middle;
}
.btnSubmit {
  font-size: medium;
  width: 100px;
  padding: 10px 20px;
  color: #fff;
  background-color: #002d5b;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  float: right;
  vertical-align: middle;
  horiz-align: right;
  border: 2px solid #002d5b;
  border-radius: 6px;
  justify-content: flex-end;
}
.registration-status-success {
  color: green;
  font-weight: bold;
  display: flex;
  text-align: center;
}
.registration-status-failure {
  color: red;
  font-weight: bold;
  display: flex;
  text-align: center;
}

.loader-container {
  display: flex;
  text-align: center;
}
a {
  color: #002d5b;
}

.loader {
  background: url("../assets/loading.svg") center no-repeat;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: 0 auto !important;
  padding: 0px 0px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
