<template>
  <div id="ship-track-footer">
    <div class="ship-track-footer-container">
      <div class="site-links">
        <!--        <a href="https://www.efwnow.com/sitemap">Site Map</a> |-->
        <a href="https://www.efwnow.com/terms-of-use">Terms of Use</a> |
        <a href="https://www.efwnow.com/privacy-policy">Privacy Policy</a>
      </div>
      <div class="copyright">
        © 2024 Estes Forwarding Worldwide. All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ship-track-footer",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
#ship-track-footer {
  height: 80px;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16),
    0 -2px 10px 0 rgba(0, 0, 0, 0.12);
  vertical-align: middle;
  width: 100%;
}

.ship-track-footer-container {
  padding: 20px 20px 0 0;
}

.site-links {
  text-align: right;
}
.site-links a {
  color: #00345e;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.copyright {
  color: #808285;
  font-size: 12px;
  text-align: right;
}

@media (max-width: 600px) {
  .site-links,
  .copyright {
    text-align: center;
  }
}
</style>
