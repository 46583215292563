import { createRouter, createWebHistory } from "vue-router";
import ShipTrackLookup from "@/components/ShipTrackLookup.vue";
import ShipTrackIndex from "@/components/ShipTrackIndex.vue";

const routes = [
  {
    path: "/",
    name: "ShipTrackIndex",
    component: ShipTrackIndex,
  },

  // perform the lookup by passing an id to the Lookup component
  {
    path: "/track/:trackId",
    name: "ShipTrackLookup",
    component: ShipTrackLookup,
    props: true,
  },
  {
    path: "/track/:trackId/:zipcode",
    name: "ShipTrackLookupWithZip",
    component: ShipTrackLookup,
    props: true,
  },

  // catch all redirect
  { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
