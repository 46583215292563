<template>
  <div id="ship-track-contact">
    For any questions, call {{ this.contactName }} at
    <a v-if="isWalmartURL" :href="`tel:${walmartContactPhoneNumber}`">{{
      walmartContactPhoneNumber
    }}</a>
    <a v-else :href="`tel:${contactPhoneNumber}`">{{ contactPhoneNumber }}</a>
    or email us at
    <a v-if="isWalmartURL" :href="`mailto:${walmartContactEmail}`">{{
      walmartContactEmail
    }}</a>
    <a v-else :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
    {{ this.referenceText }}
  </div>
</template>

<script>
export default {
  name: "ship-track-contact",
  data() {
    return {};
  },
  props: {
    contactName: {
      default: "EFW Control Tower",
      type: String,
    },
    contactPhoneNumber: {
      default: "(855) 633-9669",
      type: String,
    },
    walmartContactPhoneNumber: {
      default: "(855) 533-9669",
      type: String,
    },
    contactEmail: {
      default: "updates@efwnow.com",
      type: String,
    },
    walmartContactEmail: {
      default: "EFWWalmart@efwnow.com",
      type: String,
    },
    referenceText: {
      default: "",
      type: String,
    },
  },
  components: {},
  computed: {
    isWalmartURL() {
      return window.location.hostname === process.env.VUE_APP_WALMART_URL;
    },
  },
};
</script>

<style scoped>
#ship-track-contact {
  color: #808285;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin: 0 40px 60px 40px !important;
  text-align: center;
  vertical-align: baseline;
}

#ship-track-contact a {
  text-decoration: none;
  color: #808285;
}

/*.mailto-link {*/
/*  text-decoration: none;*/
/*  color: #808285;*/
/*}*/

@media screen and (max-width: 550px) {
  #ship-track-contact {
    padding: 0 10px;
    margin: 0 10px 60px 10px !important;
  }
}
@media screen and (max-width: 400px) {
  #ship-track-contact {
    margin: 0 0 60px 0 !important;
  }
}
</style>
