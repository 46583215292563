<template>
  <div id="ship-track-header-search">
    <div class="ship-track-header-search-container">
      <div class="header-logo">
        <!--<img src="../../assets/efw_logo.png" v-on:click="headerClicked">-->
        <!--<span class="ship-track-lookup-title">Shipment Tracking</span>-->
        <img
          src="../../assets/EFWInteriorLogo@2x.png"
          v-on:click="headerClicked"
        />
      </div>
      <!--<div class="ship-track-lookup-title-container">-->
      <!--<div class="ship-track-lookup-title">Shipment Tracking</div>-->
      <!--</div>-->
      <div class="ship-track-lookup-title-container">
        <span class="ship-track-lookup-title">{{ this.pageHeaderTitle }}</span>
      </div>
      <div class="ship-track-lookup-form-container">
        <input
          type="text"
          v-model="newTrackingNumber"
          class="tracking-number-input"
          placeholder="enter tracking number"
          @keyup.enter="doTrack"
        />
        <button class="track-button" @click="doTrack">Track</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ship-track-header-search",
  data() {
    return {
      newTrackingNumber: "",
    };
  },
  methods: {
    headerClicked: function () {
      this.$router.push("/");
    },
    doTrack: function () {
      if (this.newTrackingNumber !== "") {
        this.$router.push({
          name: "ShipTrackLookup",
          params: { trackId: this.newTrackingNumber },
        });
        this.newTrackingNumber = "";
      } else {
        // TODO: Throw error OR disable button unless entered certain amount of text?
      }
    },
  },
  props: {
    pageHeaderTitle: {
      default: "",
      type: String,
    },
  },
  components: {},
};
</script>

<style scoped>
#ship-track-header-search {
  display: block;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  text-align: left;
}

.ship-track-header-search-container {
  display: flex;
  margin: 0 50px !important;
  flex-direction: row;
}

.header-logo {
  flex-grow: 1;
}

.header-logo img {
  display: block;
  flex-grow: 1;
  vertical-align: middle;
  /*width: 90px;*/
  width: 250px;
  padding: 26px 0;
  cursor: pointer;
}

.ship-track-lookup-title-section {
  height: 65px;
  margin: 0 10px 10px 10px !important;
  /*vertical-align: middle;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ship-track-lookup-title-container {
  flex-grow: 1;
  display: block;
  vertical-align: middle;
  text-align: center;
  margin: 26px 0px 0px 0px !important;
}

.ship-track-lookup-title {
  color: #002d5b;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  vertical-align: middle;
}

.ship-track-lookup-form-container {
  vertical-align: middle;
  padding: 20px 0 0 10px;
  flex-grow: 1;
  text-align: right;
}

.tracking-number-input {
  min-width: 25%;
  font-size: 16px;
  padding: 8px 10px;
  border: 1px solid #002d5b;
  border-radius: 6px;
  background: #fff;
  color: #002d5b;
  line-height: 20px;
  vertical-align: middle;
  outline: none;
}

.track-button {
  padding: 10px 20px;
  background-color: #002d5b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #002d5b;
  border-radius: 6px;
  margin: 10px 5px !important;
  margin-right: 0 !important;
}

.track-button:focus {
  outline: 0;
}

@media (max-width: 675px) {
  .header-logo {
    text-align: center;
  }
  .ship-track-lookup-form-container {
    flex-direction: column;
    padding: 10px 0;
    text-align: center;
  }
  .ship-track-header-search-container {
    flex-direction: column;
  }

  .tracking-number-input {
    width: 75%;
  }
  .header-logo img {
    padding: 26px 0 0 0;
    width: 300px;
  }
}

@media (max-width: 550px) {
  .ship-track-header-search-container {
    margin: 0 20px !important;
  }

  .tracking-number-input {
    width: 100%;
    padding: 8px 0 8px 10px;
    box-sizing: border-box;
  }

  .track-button {
    margin: 3px 0 0 0 !important;
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .header-logo img {
    width: 250px;
  }
}
</style>
