<template>
  <div></div>
</template>

<script>
export default {
  name: "ship-track-chat",
  data() {
    return {};
  },
  methods: {
    create: function () {
      let chatScript = document.getElementById("chatBotScript");
      if (chatScript === null) {
        let chatScript = document.createElement("script");
        chatScript.id = "chatBotScript";
        chatScript.type = "text/javascript";
        chatScript.textContent =
          "icPatronChat.init({serverHost:'https://home-c32.nice-incontact.com',bus_no:4597861,poc:'" +
          this.chatKey +
          "',params:['FirstName','Last Name','first.last@company.com',555-555-5555]});";
        // document.body.insertBefore(chatScript, document.body.firstChild)
        let head = document.getElementsByTagName("head")[0];
        head.appendChild(chatScript);
      }
    },
    destroy: function () {
      let chatScript = document.getElementById("chatBotScript");
      if (chatScript !== null) {
        chatScript.parentElement.removeChild(chatScript);
      }

      // let chatBotContainer = document.getElementById('icChat')
      let chatBotContainer = document.querySelector(
        ".ie-div-position-customer-chat"
      );
      if (chatBotContainer !== null) {
        chatBotContainer.parentElement.removeChild(chatBotContainer);
      }
    },
  },
  watch: {
    chatKey(newKey, oldKey) {
      if (newKey !== oldKey) {
        this.destroy();
        this.create();
      } else if (newKey === "") {
        this.destroy();
      }
    },
  },
  created() {
    this.create();
  },
  beforeUnmount() {
    this.destroy();
  },
  props: {
    chatKey: String,
  },
};
</script>
