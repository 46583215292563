import { createStore } from "vuex";

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export default createStore({
  state() {
    return {
      isLoggedIn: !!localStorage.getItem("token"),
      pending: false,
    };
  },
  mutations: {
    [LOGIN](state) {
      state.pending = true;
    },
    [LOGIN_SUCCESS](state) {
      state.isLoggedIn = true;
      state.pending = false;
    },
  },
  actions: {
    async login({ commit }) {
      commit(LOGIN); // show spinner

      try {
        commit(LOGIN); // show spinner
        return new Promise((resolve) => {
          setTimeout(() => {
            localStorage.setItem("token", "EFW-WalMart");
            commit(LOGIN_SUCCESS);
            resolve();
          }, 1000);
        });
      } catch (error) {
        // Handle login failure here
        console.error("Login failed:", error);
      }
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
  },
});
