<template>
  <div id="ship-track-index">
    <ship-track-header-search :pageHeaderTitle="this.pageTitle" />
    <!--    <alert-message class="alert-container" :visible="alertMessageVisible"></alert-message>-->
    <alert-message class="alert-container"></alert-message>
    <div class="ship-track-lookup-container">
      <div class="ship-track-lookup-content">
        <!--<div class="ship-track-lookup-title-section">-->
        <!--<span class="ship-track-lookup-title">Shipment Tracking</span>-->
        <!--<span class="ship-track-lookup-form-container">-->
        <!--<input-->
        <!--type="text"-->
        <!--v-model="newTrackingNumber"-->
        <!--class="tracking-number-input"-->
        <!--placeholder="enter tracking number"-->
        <!--@keyup.enter="doTrack" />-->
        <!--<button class="track-button" @click="doTrack">Track</button>-->
        <!--</span>-->
        <!--</div>-->
        <div class="ship-track-data-content">
          <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
          </div>
          <ship-track-zipcode
            v-if="zipNeeded && !isLoading"
            :trackingNumber="trackId"
          />
          <ship-track-no-results
            v-if="!hasResults && !isLoading && !zipNeeded"
            :trackingNumber="trackId"
          />
          <ship-track-result
            v-for="result in trackingResults"
            v-bind:key="result.trackingNumber"
            :trackingData="result"
            :daNumber="metaDataDaNumber"
            :daWebsite="metaDataDaWebsite"
            :podName="metaDataPODName"
            :podDate="metaDataPODDate"
            :expanded="trackingResults.length === 1"
            :isMultiLoad="trackingResults.length > 1"
            :emailAddress="email"
          />
          <ship-track-chat v-if="showChat" :chatKey="chatKeyNum" />
          <ship-track-chat-help-dialog v-if="showChatHelp" />
          <walmart-login-modal
            v-show="isLoginModalVisible"
            @close="closeLoginModal"
          />
        </div>
      </div>
    </div>
    <ship-track-contact
      :contactName="contactName"
      :contactPhoneNumber="contactPhone"
      :contactEmail="email"
      :referenceText="referenceText"
    />
    <ship-track-footer />
  </div>
</template>

<script>
import webservices from "@/webservices";
import AlertMessage from "@/components/AlertMessage";
import WalmartLoginModal from "@/components/WalmartLoginModal.vue";
import {
  ShipTrackHeaderSearch,
  ShipTrackFooter,
  ShipTrackContact,
  ShipTrackChat,
  ShipTrackChatHelpDialog,
} from "@/components/partials";
import {
  ShipTrackZipcode,
  ShipTrackNoResults,
  ShipTrackResult,
} from "@/components/shiptrack";

export default {
  name: "ship-track-index",
  data() {
    return {
      metaDataDaWebsite: "",
      metaDataDaNumber: "",
      metaDataPODName: "",
      metaDataPODDate: "",
      isLoading: true,
      zipNeeded: false,
      showChat: false,
      showChatHelp: false,
      isLoginModalVisible: false,
      // alertMessageVisible: true,
      newTrackingNumber: "",
      trackingResults: [],
      noResultsProp: {},
      pageTitle: "",
      contactName: "EFW Control Tower",
      contactPhone: "(855) 633-9669",
      chatKeyNum: "",
      email: "updates@efwnow.com",
      referenceText: "",
      contactDomestic: "EFW Control Tower",
      contactInternational: "EFW International Department",
      contactTruckload: "EFW Truckload Team",
    };
  },
  computed: {
    hasResults: function () {
      return this.trackingResults.length > 0;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    checkVendorTimesForChat: function checkOperatingTimes() {
      // returns array with start and end time for showing chat for vendor
      if (this.email !== null) {
        let startEndTimes = [];
        switch (this.emailUsername(this.email).toLowerCase()) {
          case "overstock":
            startEndTimes = [8, 17];
            break;
          case "mcgeeandco":
            startEndTimes = [8, 17];
            break;
          case "target":
            startEndTimes = [8, 17];
            break;
          case "targetreturns":
            startEndTimes = [8, 17];
            break;
          case "efwwalmart":
            startEndTimes = [8.5, 19];
            break;
          default:
            startEndTimes = [8.5, 17];
        }
        return startEndTimes;
      }
      return null;
    },
    calcTimer() {
      let d = new Date();

      let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      let offset = Math.max(jan, jul) !== d.getTimezoneOffset() ? -4 : -5;

      // get UTC time in msec
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      // generate eastern time value
      let easternTime = new Date(utc + 3600000 * offset);
      return easternTime.getHours() + easternTime.getMinutes() / 60;
    },
    emailUsername: function emailUsername(emailAddress) {
      return emailAddress.split("@")[0];
    },
    trackShipment: function () {
      this.trackingResults = [];
      this.isLoading = true;
      this.zipNeeded = false;
      this.showChat = false;
      this.showChatHelp = false;

      let trackingId = this.trackId.replace(/[^a-zA-Z0-9-]/g, "");

      let trackData = { TrackNo: trackingId, ConZip: this.zipcode };
      webservices
        .getTracking(trackData)
        .then((response) => {
          if (response.data.metadata !== null) {
            let metadata = response.data.metadata;
            if (metadata.DANumber !== null) {
              this.metaDataDaNumber = metadata.DANumber;
            }

            if (metadata.DAWebsite !== null) {
              this.metaDataDaWebsite = metadata.DAWebsite;
            }

            if (metadata.PODName !== null) {
              this.metaDataPODName = metadata.PODName;
            }

            if (metadata.PODDate !== null) {
              this.metaDataPODDate = metadata.PODDate;
            }

            if (metadata.needed !== null) {
              if (metadata.needed === "zip") {
                this.zipNeeded = true;
              }
            }

            if (metadata.phone !== null) {
              this.contactPhone = this.formatPhone(metadata.phone);
            }

            if (metadata.email !== null) {
              this.email = metadata.email;
            }
            //TODO: ensure that updated g-tag works? And remove commented out $ga event below once confirmed
            this.$gtag.event("track", {
              event_category: "shipment",
              event_label: "partner",
              value: this.email.toLowerCase(),
            });
            // this.$ga.event({
            //   eventCategory: "shipment",
            //   eventAction: "track",
            //   eventLabel: "partner",
            //   eventValue: this.email.toLowerCase(),
            // });

            // let timeConvertedToEDT = this.calcTimer(-4)
            let timeConvertedToEDT = this.calcTimer();
            let vendorOpenTimes = this.checkVendorTimesForChat();

            console.log("##################");
            console.log(timeConvertedToEDT);
            console.log(vendorOpenTimes);
            console.log("##################");

            this.chatKeyNum = "";
            this.showChat = false;
            // if walmart check for logged in state for walmart chat
            if (window.location.hostname === process.env.VUE_APP_WALMART_URL) {
              if (this.isLoggedIn) {
                this.chatKeyNum = process.env.VUE_APP_WALMART_CHAT_KEY;
                this.showChat = true;
              }
            } else {
              // check tnt flag info
              if (metadata.TNTflag && metadata.TNTkey) {
                if (metadata.TNTflag === "true" && metadata.TNTkey !== "") {
                  // check vendor operation times to determine whether or not to show chat
                  if (
                    timeConvertedToEDT >= vendorOpenTimes[0] &&
                    timeConvertedToEDT <= vendorOpenTimes[1]
                  ) {
                    this.chatKeyNum = metadata.TNTkey;
                    this.showChat = true;
                  }
                }
              } else if (metadata.chatflag && metadata.chatkey) {
                // check if chatflag/key is available
                if (metadata.chatflag === "true" && metadata.chatkey !== "") {
                  // check vendor operation times to determine whether or not to show chat
                  if (
                    timeConvertedToEDT >= vendorOpenTimes[0] &&
                    timeConvertedToEDT <= vendorOpenTimes[1]
                  ) {
                    this.chatKeyNum = metadata.chatkey;
                    this.showChat = true;
                  }
                }
              }
            }

            // if (this.showChat) {
            this.chatButtonCheck();
            // }
          }

          const deliveryShipments = [];
          const otherShipments = [];
          if (response.data.payload !== null) {
            for (let i = 0; i < response.data.payload.length; i++) {
              let shipment = response.data.payload[i];
              if (shipment.scheduledDELactive) {
                deliveryShipments.push(shipment);
              } else {
                otherShipments.push(shipment);
              }
            }

            this.trackingResults = deliveryShipments.concat(otherShipments);

            if (this.trackingResults.length > 0) {
              let shipment = this.trackingResults[0];
              if (shipment.shipmenttype === "international") {
                this.contactName = this.contactInternational;
                this.pageTitle = "International";
              } else if (shipment.shipmenttype === "truckload") {
                this.contactName = this.contactTruckload;
                this.pageTitle = "Truckload";
              } else {
                this.contactName = this.contactDomestic;
                this.pageTitle = "";
              }
            }
          }

          this.isLoading = false;
        })
        .catch((err) => {
          console.log("Err: " + err);
          this.isLoading = false;
          // show chat if walmart url and logged in
          if (
            window.location.hostname === process.env.VUE_APP_WALMART_URL &&
            this.isLoggedIn
          ) {
            this.chatKeyNum = process.env.VUE_APP_WALMART_CHAT_KEY;
            this.showChat = true;
          }
        });
    },
    doTrack: function () {
      if (this.newTrackingNumber !== "") {
        this.$router.push({
          name: "ShipTrackLookup",
          params: { trackId: this.newTrackingNumber },
        });
        this.newTrackingNumber = "";
        this.resetPageData(); // TODO: Keep here or move into other logic need to test
      } else {
        // TODO: Throw error OR disable button unless entered certain amount of text?
      }
    },
    formatPhone: function (phoneRaw) {
      let p = phoneRaw.replace(/[^A-Za-z 0-9]+/g, "");

      var formattedPhone =
        "(" +
        p.substring(0, 3) +
        ") " +
        p.substring(3, 6) +
        "-" +
        p.substring(6, 10);

      if (p.length > 10) {
        formattedPhone = formattedPhone + " " + p.substring(10);
      }

      return formattedPhone;
    },
    resetPageData() {
      this.contactName = this.contactDomestic;
      this.pageTitle = "";
    },
    showLoginModal: function () {
      if (window.location.hostname === process.env.VUE_APP_WALMART_URL) {
        this.isLoginModalVisible = !this.isLoggedIn;
      }
    },
    closeLoginModal() {
      this.isLoginModalVisible = false;
      if (!this.isLoggedIn) {
        window.location = `https://${process.env.VUE_APP_SITE_URL}/track/${this.trackId}`;
      }
    },
    chatButtonCheck() {
      if (this.showChat) {
        const chatButton = "icChatButton";
        if (document.getElementById(chatButton)) {
          // let chatButtonWindow = document.getElementById(chatButton)
          // let chatButtonElem = chatButtonWindow.contentWindow.document.getElementById('divChatButton')
          // console.log(chatButtonElem)

          setTimeout(() => {
            this.showChatHelp = true;
            this.chatWindowCheck();
          }, 2000);
        } else {
          setTimeout(this.chatButtonCheck, 1000);
        }
      }
    },
    chatWindowCheck() {
      if (this.showChat) {
        const chatWindowId = "icChat";
        const chatWindowDisplayClass = "iframe-shadow";
        let chatWindow = document.getElementById(chatWindowId);
        if (
          chatWindow &&
          chatWindow.classList.contains(chatWindowDisplayClass)
        ) {
          this.showChatHelp = false;
        } else {
          setTimeout(this.chatWindowCheck, 250);
        }
      }
    },
  },
  watch: {
    trackId: function () {
      this.trackShipment();
    },
    zipcode: function () {
      this.trackShipment();
    },
    isLoggedIn: function () {
      // show chat if walmart url and logged in
      // if (window.location.hostname === process.env.VUE_APP_WALMART_URL && this.isLoggedIn) {
      this.chatKeyNum = process.env.VUE_APP_WALMART_CHAT_KEY;
      this.showChat = true;
      // }
    },
  },
  created: function () {
    this.trackShipment();
    this.showLoginModal();
  },
  props: {
    trackId: {
      type: String,
    },
    zipcode: {
      type: String,
      default: "NONE",
    },
  },
  components: {
    AlertMessage,
    ShipTrackResult,
    ShipTrackZipcode,
    ShipTrackNoResults,
    ShipTrackHeaderSearch,
    ShipTrackFooter,
    ShipTrackContact,
    ShipTrackChat,
    WalmartLoginModal,
    ShipTrackChatHelpDialog,
  },
};
</script>

<style scoped>
#ship-track-index {
  display: block;
  height: 100%;
}

.alert-container {
  padding: 20px 100px 20px 100px !important;
}

.ship-track-lookup-container {
  display: flex;
  justify-content: center;
  margin: 20px 40px !important;
  min-height: calc(100% - 80px - 80px - 40px);
}

.ship-track-lookup-content {
  width: 100%;
}

.ship-track-lookup-title-section {
  height: 65px;
  margin: 0px 10px 10px 10px !important;
  /*vertical-align: middle;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ship-track-lookup-title {
  color: #002d5b;
  font-size: 20px;
  font-weight: 700;
  /*vertical-align: middle;*/
  line-height: normal;
}

.ship-track-lookup-form-container {
  height: 100%;
}

.tracking-number-input {
  min-width: 25%;
  font-size: 16px;
  padding: 8px 10px;
  border: 1px solid #002d5b;
  border-radius: 6px;
  background: #fff;
  color: #002d5b;
  line-height: 20px;
  vertical-align: middle;
  outline: none;
}

.track-button {
  padding: 10px 20px;
  background-color: #002d5b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #002d5b;
  border-radius: 6px;
  margin: 10px !important;
}

.track-button:focus {
  outline: 0;
}

.ship-track-data-content {
  margin: auto 10px !important;
}

.loader-container {
  text-align: center;
}

.loader {
  background: url("../assets/loading.svg") center no-repeat;
  /*border: 16px solid #f3f3f3; !* Light grey *!*/
  /*border-top: 16px solid #3498db; !* Blue *!*/
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  margin: 0 auto !important;
  padding: 60px 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 675px) {
  .alert-container {
    padding: 20px 30px 20px 30px !important;
  }
}

@media screen and (max-width: 550px) {
  .ship-track-lookup-container {
    margin: 1.25rem 0.625rem !important; /* 20px 10px */
  }
}

@media screen and (max-width: 400px) {
  .ship-track-lookup-container {
    margin: 1.25rem 0.46875rem !important; /* 20px 7.5px */
  }
}
</style>
