<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-terms"
        role="dialog"
        aria-label="termsConditions"
        aria-labelledby="modalTitle"
      >
        <header class="modal-terms-header" id="modalTitle">
          <h4 class="term-title" name="header">Terms and Conditions</h4>
        </header>
        <div class="terms-conditions">
          If you confirm your agreement to the EFW SMS Program Terms and
          Conditions and the
          <a href="https://www.efwnow.com/privacy-policy/">EFW Privacy Policy</a
          >, you will be subscribed to receive text messages from EFW,
          containing information about packages which you request, on the mobile
          device that is personally owned by you. You represent that you have
          the authority to agree to receive SMS messages on the telephone number
          that you provided to us. Message frequency depends upon shipment
          activity. Standard message and data rates may apply. SMS messaging is
          not available in all areas. Not all mobile devices or handsets may be
          supported. EFW and the mobile carriers are not liable for delayed or
          undelivered messages.
        </div>
        <div style="padding-bottom: 10px">
          <button
            type="button"
            class="btn-close-terms"
            @click="close"
            aria-label="Close modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modalTerms",
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.terms-conditions {
  padding: 0 0px 20px 0px;
  display: block;
  text-align: left;
}
.term-title {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-terms {
  width: 420px;
  background: #fff;
  border: 2px solid #002d5b;
  border-radius: 6px;
  /*box-shadow: 1px 1px 1px 1px;*/
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.modal-terms-header {
  padding: 10px 0px;
  display: flex;
}

.modal-terms-header {
  font-weight: bold;
  font-size: large;
  color: #002d5b;
  justify-content: space-between;
}
.btn-close-terms:focus {
  outline: 0;
}
.btn-close-terms {
  font-size: medium;
  width: 100px;
  text-align: center;
  padding: 10px 20px;
  border: 2px solid #002d5b;
  border-radius: 6px;
  cursor: pointer;
  color: #002d5b;
  float: left;
  background: transparent;
}
</style>
