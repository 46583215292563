<template>
  <div id="ship-track-index">
    <!--<ship-track-header />-->
    <div class="ship-track-index-container">
      <div class="ship-track-index-content">
        <div class="ship-track-index-title">
          <img src="../assets/EFWLandingLogo@2x.png" />
        </div>
        <div class="ship-track-index-instructions">
          To view the most up-to-date status of your shipment, enter your
          tracking number below.
        </div>
        <div>
          <input
            type="text"
            v-model="trackingNumber"
            class="tracking-number-input"
            placeholder="enter tracking number"
            @keyup.enter="doTrack"
          />
          <button class="track-button" @click="doTrack">Track</button>
        </div>
        <div class="alert-message-container">
          <!--          <alert-message :visible="alertMessageVisible" :inverted="alertMessageVisible"/>-->
          <alert-message :inverted="true" />
        </div>
        <div>
          <walmart-login-modal
            v-show="isLoginModalVisible"
            @close="closeLoginModal"
          />
          <ship-track-chat v-if="showChat" :chatKey="chatKeyNum" />
        </div>
      </div>
    </div>
    <ship-track-contact />
    <ship-track-footer />
  </div>
</template>

<script>
import AlertMessage from "@/components/AlertMessage";
import {
  // ShipTrackHeader,
  ShipTrackFooter,
  ShipTrackContact,
  ShipTrackChat,
} from "@/components/partials";
import WalmartLoginModal from "@/components/WalmartLoginModal.vue";

export default {
  name: "ship-track-index",
  data() {
    return {
      trackingNumber: "",
      // alertMessageVisible: true,
      isLoginModalVisible: false,
      chatKeyNum: "",
      showChat: false,
    };
  },
  methods: {
    doTrack: function () {
      if (this.trackingNumber !== "") {
        this.$router.push({
          name: "ShipTrackLookup",
          params: { trackId: this.trackingNumber },
        });
      }
    },
    showLoginModal: function () {
      if (window.location.hostname === process.env.VUE_APP_WALMART_URL) {
        this.isLoginModalVisible = !this.isLoggedIn;
      }
    },
    closeLoginModal() {
      this.isLoginModalVisible = false;
      // if not authenticated, route back to efwtrack.com
      if (this.isLoggedIn) this.checkVendorTimesForChat();
      else window.location = `https://${process.env.VUE_APP_SITE_URL}`;
    },
    checkVendorTimesForChat: function () {
      const walmartOpenTimes = [8.5, 19];
      let timeConvertedToEDT = this.calcTimer(-4);
      this.chatKeyNum = "";
      this.showChat = false;
      this.chatKeyNum = process.env.VUE_APP_WALMART_CHAT_KEY;
      // check vendor operation times to determine whether or not to show chat
      if (
        timeConvertedToEDT >= walmartOpenTimes[0] &&
        timeConvertedToEDT <= walmartOpenTimes[1]
      ) {
        this.showChat = true;
      }
    },
    calcTimer: function calcTime(offset) {
      let d = new Date();
      // get UTC time in msec
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      // generate eastern time value
      let easternTime = new Date(utc + 3600000 * offset);
      return easternTime.getHours() + easternTime.getMinutes() / 60;
    },
  },
  created: function () {
    this.showLoginModal();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  components: {
    AlertMessage,
    // ShipTrackHeader,
    ShipTrackFooter,
    ShipTrackContact,
    WalmartLoginModal,
    ShipTrackChat,
  },
};
</script>

<style scoped>
#ship-track-index {
  display: block;
  height: 100%;
}

.alert-message-container {
  display: inline-block;
  align-items: center;
  text-align: center;
  max-width: 70%;
  padding-top: 10px;
}

.ship-track-index-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin: 100px 40px !important;*/
  margin: 100px 40px 40px 40px !important;
  min-height: calc(100% - 80px - 80px - 40px);
}

.ship-track-index-content {
  width: 100%;
  height: 100%;
  max-width: 850px;
}

.ship-track-index-title {
  color: #002d5b;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px !important;
}

.ship-track-index-title img {
  height: 160px;
  width: 400px;
}

.ship-track-index-instructions {
  max-width: 400px;
  margin: 0 auto 20px !important;
  font-size: 16px;
  color: #002d5b;
}

.tracking-number-input {
  min-width: 50%;
  font-size: 16px;
  padding: 8px 10px;
  border: 1px solid #002d5b;
  border-radius: 6px;
  background: #fff;
  color: #002d5b;
  line-height: 20px;
  vertical-align: middle;
  outline: none;
}

.track-button {
  padding: 10px 20px;
  background-color: #002d5b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #002d5b;
  border-radius: 6px;
  margin: 10px !important;
}

.track-button:focus {
  outline: 0;
}

@media (max-width: 600px) {
  .ship-track-index-title img {
    height: 120px;
    width: 300px;
  }

  .alert-message-container {
    max-width: 95%;
  }
}
</style>
