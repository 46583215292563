<template>
  <div id="ship-track-zipcode">
    <div class="zipcode-header">EFW Tracking</div>
    <div class="zipcode-content">
      <div class="zipcode-text">
        <p>
          We seem to need a little more information to find your tracking
          information.
        </p>
        <p>Please provide your zip code and we'll look that up for you.</p>
      </div>
      <div>
        <input
          type="text"
          v-model="zipCode"
          class="zip-code-input"
          placeholder="enter postal code"
          v-on:keypress="isNumber"
          @keyup.enter="doTrack"
          autofocus
        />
        <button class="track-button" @click="doTrack">Track</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ship-track-zipcode",
  data() {
    return {
      zipCode: "",
    };
  },
  methods: {
    doTrack: function () {
      if (this.zipCode !== "" && this.zipCode.length >= 5) {
        this.$router.push({
          name: "ShipTrackLookupWithZip",
          params: { trackId: this.trackingNumber, zipcode: this.zipCode },
        });
      }
    },
    isNumber: function (e) {
      e = e === null ? e : window.event;
      var charCode = e.which ? e.which : e.keyCode;
      // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      if (charCode >= 48 && charCode <= 57) {
        if (this.zipCode.length > 4) {
          e.preventDefault();
        } else {
          return true;
        }
      } else {
        e.preventDefault();
      }
    },
  },
  created: function () {
    // console.log('ship-track-zipcode: created')
  },
  props: {
    trackingNumber: {
      default: "XXXXXXX",
      type: String,
    },
  },
  components: {},
};
</script>

<!-- styling for the component -->
<style scoped>
#ship-track-zipcode {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16),
    0 -2px 10px 0 rgba(0, 0, 0, 0.12);
}

.zipcode-header {
  padding: 20px;
  color: #ffffff;
  background-color: #002d5b;
  visibility: visible;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.zipcode-content {
  color: black;
  padding: 60px;
}

.zipcode-text {
  margin: 0px 0px 20px 0px !important;
}

.zip-code-input {
  min-width: 25%;
  font-size: 16px;
  padding: 8px 10px;
  border: 1px solid #002d5b;
  border-radius: 6px;
  background: #fff;
  color: #002d5b;
  line-height: 20px;
  vertical-align: middle;
  outline: none;
}

.track-button {
  padding: 10px 20px;
  background-color: #002d5b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #002d5b;
  border-radius: 6px;
  margin: 10px auto !important;
}

@media (max-width: 556px) {
  .zipcode-content {
    padding: 40px;
  }

  .zip-code-input {
    width: 100%;
    padding: 8px 0 8px 10px;
    box-sizing: border-box;
  }

  .track-button {
    margin: 3px 0 0 0 !important;
    width: 100%;
  }
}
</style>
